import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import Touch from 'vuetify/lib/directives/touch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-view',{attrs:{"title":_vm.$t('app.route.favorites'),"fab":"mdi-arrow-up"},on:{"fab:click":function($event){return _vm.goTop()}}},[_c('div',{staticClass:"d-flex"},[(!_vm.notEmptyArray(_vm.getSections))?_c('center-content',[_vm._v(" "+_vm._s(_vm.$t("favorites.messages.noItems"))+" ")]):_vm._e(),_c('bookmark-tabs',{staticStyle:{"width":"100%"},attrs:{"items":_vm.getSections,"id-prefix":"sec-","container":_vm.$refs.viewFrame},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1),_c('div',{ref:"viewFrame",staticClass:"overflow-y-auto px-5",style:(_vm.getViewHeightStyle(76))},_vm._l((_vm.getSections),function(sec,i){return _c('div',{key:i},[_c('section-header',{attrs:{"title":sec.name,"bookmark":'sec-' + sec.id,"icon":"mdi-shape-outline"}}),_c(VRow,{directives:[{def: Touch,name:"touch",rawName:"v-touch",value:({
          left: function () { return _vm.swipe(sec.id, 'left'); },
          right: function () { return _vm.swipe(sec.id, 'right'); },
        }),expression:"{\n          left: () => swipe(sec.id, 'left'),\n          right: () => swipe(sec.id, 'right'),\n        }"}],staticClass:"mb-5"},_vm._l((sec.products),function(item){return _c(VCol,{key:item.id,staticClass:"pr-3",attrs:{"xl":"2","lg":"3","md":"4","sm":"6","cols":"6"}},[_c('product-card',{attrs:{"item":item,"favored":true,"show-rating":""},on:{"click:card":function () { return _vm.$router.push({ name: 'product', params: { id: item.id, back: { name: 'favorites' } } }); }}})],1)}),1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }